/* eslint-disable eqeqeq */
import React from 'react';
import { formatDate, getImageUrl } from 'utils/appHelpers';
import ClickArea from 'shared/components/ClickArea';
import Loading from 'shared/components/Loading';
import ListActionButtons from 'shared/components/ListActionButtons';

const headers = [
  {
    name: 'Name',
    className: 'col',
    key: 'fullName',
  },
  {
    name: 'Registered At',
    className: 'col',
    key: 'registeredAt',
  },
  {
    name: 'Actions',
    className: 'p-2 d-flex action-buttons-container min_60',
    key: '',
  },
];

const completedHeaders = [
  {
    name: 'Name',
    className: 'col',
    key: 'fullName',
  },
  {
    name: 'Registered At',
    className: 'col',
    key: 'registeredAt',
  },
  {
    name: 'Completed',
    className: 'col',
    key: 'completedAt',
  },
  {
    name: 'Actions',
    className: 'p-2 d-flex action-buttons-container min_60',
    key: '',
  },
];

const UsersLists = ({ users, order, changeOrder, status, fetch, isCompleted }) => {
  if (fetch) return <Loading className='mt-5' />;

  if (!users || !users?.length)
    return <div className='text-black-50 text-center mt-4'>No Results Found</div>;

  const handleOrderChange = item => {
    if (!item.key) return;
    changeOrder({ key: item.key, order: !order.order });
  };

  const invitedStatus = Number(status) === 4;
  const rowHeaders = isCompleted ? completedHeaders : headers;

  const actions = [
    {
      type: 'link',
      icon: 'view',
      to: item =>
        `/user/${item.learnerProfile?.userId || item.id}/${
          invitedStatus || item.userStatus == 4 ? 'invited' : ''
        }`,
    },
  ];

  return (
    <div className='users-lists'>
      <ul className='p-0'>
        <li className='d-flex list-header align-items-center'>
          {rowHeaders.map((item, index) => (
            <ClickArea
              key={item.name + index}
              className={`d-flex text-body justify-content-between align-items-center ${
                item.className
              }`}
              onClick={handleOrderChange.bind(null, item)}
            >
              {item.name}
              {item.key && order.orderKey === item.key && (
                <button
                  className={`btn ml-2 p-0 dropdown-toggle ${order.order ? '' : 'rotate-180'}`}
                />
              )}
            </ClickArea>
          ))}
        </li>
        {users &&
          users.map((item, idx) => {
            return (
              <li key={idx} className='d-flex list-item h-not-set'>
                <div className='col d-flex align-items-center'>
                  <img
                    src={getImageUrl(item.imageUrl)}
                    alt={item.fullName}
                    className='user-image mr-2'
                  />
                  <div className='text-elips'>{item.fullName}</div>
                </div>
                <div className={`align-items-center col d-flex`}>
                  <span className='text-elips'>{formatDate(item.registeredAt)}</span>
                </div>
                {isCompleted && (
                  <div className={`col d-flex align-items-center`}>
                    <span className='text-elips'>{formatDate(item.completedAt)}</span>
                  </div>
                )}
                <div className='p-2 d-flex action-buttons-container min_60 align-items-center'>
                  <ListActionButtons actions={actions} item={item} />
                </div>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default UsersLists;
