import React, { useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { components } from 'react-select';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import { useSnackbar } from 'notistack';
import { IconBin } from './Icons';
import { confirmAlert } from 'react-confirm-alert';

const MyOption = props => {
  const isNew = props.data.__isNew__;
  return (
    <div className='d-flex align-items-center'>
      <components.Option {...props} />
      {!isNew && (
        <button
          type='button'
          className='btn mb-1 pb-2 position-absolute r-0'
          onClick={props.onRemoveClick}
        >
          <IconBin />
        </button>
      )}
    </div>
  );
};

const TagsSelect = ({ value, onChange, placeholder, menuPlacement = 'bottom', type }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [tags, setTags] = useState();

  const getTags = async () => {
    try {
      const { data } = await Api.get('/tag/tags', { params: { limit: 1000 } });
      if (data?.data?.results) {
        const tags = type
          ? data.data.results.filter(item => item.type === type)
          : data.data.results;
        const tagsList = tags.map(item => ({ label: item.name, value: item.id }));
        setTags(tagsList);
      }
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onTagsChange = newValue => {
    onChange(newValue);
  };

  const handleCreate = async newTag => {
    try {
      const { data } = await Api.post('/tag/add', { name: newTag, type });
      const newItem = { label: data.data.name, value: data.data.id };
      setTags([...tags, newItem]);
      onChange([...value, newItem]);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onTagRemove = async id => {
    try {
      await Api.delete(`/tag/${id}`);
      await getTags();
      enqueueSnackbar('Successfully removed', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onRemoveClick = (item, e) => {
    e.preventDefault();
    e.stopPropagation();

    confirmAlert({
      title: 'Tag Removal',
      message: `Are you sure you want to delete tag “${item.data.label}”?`,
      buttons: [
        {
          label: 'CANCEL',
        },
        {
          label: 'REMOVE',
          onClick: () => onTagRemove(item.data.value),
        },
      ],
    });
  };

  useEffect(() => {
    getTags();
    //eslint-disable-next-line
  }, []);

  const Option = props => {
    return <MyOption {...props} onRemoveClick={onRemoveClick.bind(null, props)} />;
  };

  return (
    <CreatableSelect
      className='multi-select'
      classNamePrefix='multi-select-pre'
      components={{ Option }}
      onCreateOption={handleCreate}
      value={value}
      onChange={onTagsChange}
      isMulti
      options={tags}
      placeholder={placeholder}
      menuPlacement={menuPlacement}
      formatCreateLabel={val => `+ Create a New Tag (${val})`}
    />
  );
};

export default TagsSelect;
