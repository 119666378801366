/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Api } from 'utils/connectors';
import { useSnackbar } from 'notistack';
import { getError } from 'utils/appHelpers';
import Loading from 'shared/components/Loading';
import ProductViewHeader from './components/ProductViewHeader';
import { formatDateForQuiz } from 'utils/formatHelpers';
import DetailsBlock from './components/DetailsBlock';
import PageCard from './components/PageCard';
import { PlusIcon } from 'shared/components/Icons';
import { CreateLinkDialog } from '../Products/components/CreateLinkDialog';
import { useDialog } from 'shared/hooks/useDialog';
import { confirmAlert } from 'react-confirm-alert';

const ProductView = ({ match, history }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState();
  const [removeFetch, setRemoveFetch] = useState(false);
  const [activeSpecialties, setActiveSpecialties] = useState(['all']);
  const [urlPath, setUrlPath] = useState('');

  const createLinkDialog = useDialog();

  const filteredPages =
    data?.pages?.length && activeSpecialties?.[0] === 'all'
      ? data?.pages
      : data?.pages?.filter(page => activeSpecialties.includes(page.specialty));

  const getProductData = async () => {
    try {
      const { data } = await Api.get(`/product/product/${match?.params?.id}`);
      if (data?.data) {
        setData({
          ...data?.data,
          specialities: data?.data?.pages?.map(({ specialty }) => specialty),
        });
        setUrlPath(data?.data?.urlPath);
      }
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      history.push('/products');
    }
  };

  const onPageItemPublish = async item => {
    try {
      setRemoveFetch(true);
      await Api.post(`/product/product-page/${item.id}/publish`);
      await getProductData();
      enqueueSnackbar('Successfully published', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setRemoveFetch(false);
    }
  };

  const onPageItemUnpublish = async item => {
    try {
      setRemoveFetch(true);
      await Api.post(`/product/product-page/${item.id}/unpublish`);
      await getProductData();
      enqueueSnackbar('Successfully unpublished', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setRemoveFetch(false);
    }
  };

  const onPageItemRemove = async item => {
    try {
      setRemoveFetch(true);
      await Api.delete(`/product/product-page/${item.id}`);
      await getProductData();
      enqueueSnackbar('Successfully removed', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setRemoveFetch(false);
    }
  };

  const onPageItemRemoveConfirm = item => {
    confirmAlert({
      overlayClassName: 'wide',
      title: 'Confirmation Required',
      message:
        'This is going to remove the product page and all its related data. All user progresses related to the product page are not  going to be available after deletion. Are you sure you want to delete the Product page and all its related data?',
      buttons: [
        {
          label: 'Cancel',
        },
        {
          label: 'Delete',
          onClick: () => onPageItemRemove(item),
        },
      ],
    });
  };

  const handleToggleSelectedSpecialties = specialty => {
    if (activeSpecialties.includes(specialty)) {
      const updatedSpecialties = activeSpecialties.filter(spec => spec !== specialty);
      setActiveSpecialties(updatedSpecialties?.length ? updatedSpecialties : ['all']);
    } else {
      setActiveSpecialties([...activeSpecialties.filter(spec => spec !== 'all'), specialty]);
    }
  };

  const handleCloseCreateLink = () => {
    createLinkDialog.closeDialog();
  };

  const handleOpenCreateLink = data => {
    createLinkDialog.openDialog(data);
  };

  useEffect(() => {
    getProductData();
  }, [match.params.id]);

  if (!data) return <Loading />;

  return (
    <div className='product-view-container'>
      <ProductViewHeader name={data?.name || 'This is a good product'} />
      <div className='d-flex has-header content'>
        <div className='col-9 left-block'>
          <div className='px-1'>
            <h5 className='color-blue title'>{data?.name || 'This is a good product'}</h5>
            <p className='regular-text'>Created on {formatDateForQuiz(data?.createdAt)}</p>
            <div className='img-block'>
              <img alt={data?.name} src={data?.images?.[0]?.imageUrl} />
            </div>
            {data.description && (
              <p>
                <span className='d-block white-space-pre text-black regular-text two-dimension-align'>
                  {data?.description}
                </span>
              </p>
            )}
          </div>
        </div>
        <div className='col-3 py-3'>
          <DetailsBlock
            showEntities
            handleOpenCreateLink={handleOpenCreateLink}
            className='details-view-block'
            title='Product'
            data={data}
          />
        </div>
      </div>
      <div className='col-9 pages-section'>
        <h5 className='color-blue title'>Product Pages</h5>
        <div className='pages-block'>
          {!!filteredPages?.length &&
            filteredPages.map(page => (
              <PageCard
                onItemPublish={onPageItemPublish}
                onItemUnpublish={onPageItemUnpublish}
                onItemRemove={onPageItemRemoveConfirm}
                handleClickCopyLink={() => handleOpenCreateLink({ specialty: page?.specialty })}
                page={page}
                key={page?.id}
              />
            ))}
          <Link to={`/product/${match?.params?.id}/product-page/new`} className='add-page-item'>
            <PlusIcon width={150} height={150} />
          </Link>
        </div>
      </div>
      <CreateLinkDialog
        isOpen={createLinkDialog.isOpen}
        onClose={handleCloseCreateLink}
        urlPath={urlPath}
        selectedSpecialty={createLinkDialog.dialogData?.specialty}
      />
    </div>
  );
};

export default ProductView;
