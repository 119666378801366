import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useSnackbar } from 'notistack';

import { checkCourseForPublishing, showCourseInvalidAlert } from '../CourseView/utils';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import { courseTags } from 'configs';

import FooterButtons from './components/FooterButtons';
import Header from './components/Header';
import CourseGeneralStep from './components/CourseGeneralStep';
import CourseCompleteStep from './components/CourseCompleteStep';
import CourseAuthorsStep from './components/CourseAuthorsStep';
import CourseLessonsStep from './components/CourseLessonsStep';
import CourseFeedBackStep from './components/CourseFeedBackStep';
import CourseCertificates from './components/CourseCertificates';
import Loading from 'shared/components/Loading';
import { hasAccess } from 'utils/permissionHelper';

const publishMode = 2;

const CourseNew = ({ history, match, location }) => {
  const { id } = match.params;
  const sampleCourseId = location?.state?.sampleId;
  const { enqueueSnackbar } = useSnackbar();
  const [isInUse, setIsInUse] = useState(false);
  const [course, setCourse] = useState();
  const [fetching, setFetching] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [tempLocalActiveLessons, setTempLocalActiveLessons] = useState([]);

  const [data, setData] = useState({
    activeCases: [],
    franchises: [],
    learnerTags: [],
    adminTags: [],
    lessons: [],
    quiz: null,
  });
  const [form, setForm] = useState({
    mode: 1,
    certificateId: 0,
    name: null,
    nickName: null,
    description: null,
    uploadImageId: 0,
    uploadContentId: 0,
    duration: null,
    isOpened: !hasAccess('education_course_create') ? 1 : 0,
    isEvaluationAvailable: 1,
    isCertificateAvailable: !hasAccess('education_course_create') ? 0 : 1,
    isQuizAvailable: 0,
    isAutoAccessible: 0,
    isPubliclyAvailable: 0,
    isPubliclyAccessible: 0,
    isMarketingMaterial: !hasAccess('education_course_create') ? 1 : 0,
  });

  const typeData = {
    1: {
      type: 'educational',
      steps: [
        { key: 'general', component: CourseGeneralStep },
        { key: 'authors', component: CourseAuthorsStep },
        { key: 'lessons', component: CourseLessonsStep },
        { key: 'feedback', component: CourseFeedBackStep },
        { key: 'certificate', component: CourseCertificates },
        { key: 'complete', component: CourseCompleteStep },
      ],
    },
    2: {
      type: 'marketing',
      steps: [
        { key: 'general', component: CourseGeneralStep },
        { key: 'lessons', component: CourseLessonsStep },
        { key: 'feedback', component: CourseFeedBackStep },
        { key: 'complete', component: CourseCompleteStep },
      ],
    },
  };

  const constructFormData = form => {
    form.authors = data.activeAuthors && data.activeAuthors.map(i => i.authorId);
    form.lessons = data.activeLessons && data.activeLessons.map(i => i.lessonId);
    form.feedbacks = data.feedbacks;
    form.franchises = data.franchises && data.franchises.map(i => i.id);
    form.learnerTags = data.learnerTags && data.learnerTags.map(i => i.value);
    form.adminTags = data.adminTags && data.adminTags.map(i => i.value);
    return form;
  };

  const handleSaveCourse = async formData => {
    const body = formData || constructFormData({ ...form, mode: 2 });
    if (!formData) {
      const { isValid, message } = checkCourseForPublishing(body);
      if (!isValid) {
        showCourseInvalidAlert(message);
        return;
      }
    }
    try {
      setFetching(formData ? 1 : 2);
      const endpoint = id ? '/wizard/course/update' : '/wizard/course/init';
      await Api.post(endpoint, { ...body, originId: id ? id : undefined });
      enqueueSnackbar(`Course successfully ${id ? 'updated' : 'created'}`, { variant: 'success' });
      history.push('/courses/all');
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      setFetching(false);
    }
  };

  const onCourseSave = async (formData, isUpdate) => {
    if (isUpdate && course.mode === publishMode && isInUse) {
      confirmAlert({
        overlayClassName: 'with-icon',
        title: 'Confirmation Needed',
        message:
          'The users enrolled to this Course will be AFFECTED. Are you sure you want to update?',
        buttons: [
          {
            label: 'Cancel',
          },
          {
            label: 'Update Anyway',
            onClick: () => handleSaveCourse(formData),
          },
        ],
      });
    } else {
      handleSaveCourse(formData);
    }
  };

  const onSaveDraft = () => {
    const draftForm = constructFormData({ ...form, mode: 1 });
    const cleanForm = Object.fromEntries(
      Object.entries(draftForm).filter(([_, v]) => v !== null && v !== undefined),
    );
    onCourseSave(cleanForm);
  };

  const onGoBackStep = () => {
    setActiveStep(step => step - 1);
  };

  const handleStepChange = async e => {
    e.preventDefault();
    setActiveStep(step => step + 1);
  };

  const setInitialAuthors = data => {
    if (!data) return [];
    return data.map(({ authorProfile: item }) => {
      item.firstName = item.name;
      item.lastName = item.surname;
      item.authorId = item.id;
      return item;
    });
  };

  const setInitialLessons = data => {
    if (!data) return [];
    return data.map(({ lesson: item }) => {
      item.lessonId = item.id;
      return item;
    });
  };

  const setInitialFeedbacks = data => {
    if (!data) return [];
    return data.map(({ feedback: item }) => {
      item.feedbackType = item.type;
      item.labels = item.feedbackItems ? item.feedbackItems.map(item => item.label) : [''];
      return item;
    });
  };

  const setInitialFranchises = data => {
    if (!data) return [];
    return data.map(({ franchise }) => franchise);
  };

  const setInitialTags = (res, type) => {
    if (!res) return [];
    const data = res.filter(({ tag }) => tag.type === type);
    return data.map(({ tag }) => ({ label: tag.name, value: tag.id }));
  };

  const setCourseInitialData = course => {
    setForm({
      name: course.title || null,
      nickName: course.nickName || null,
      description: course.info || null,
      duration: course.duration || null,
      isOpened: course.isOpened,
      isEvaluationAvailable: course.isEvaluationAvailable,
      isCertificateAvailable: course.isCertificateAvailable,
      isQuizAvailable: course.isQuizAvailable || 0,
      isPubliclyAccessible: course.isPubliclyAccessible,
      isPubliclyAvailable: course.isPubliclyAvailable,
      isAutoAccessible: course.isAutoAccessible,
      isMarketingMaterial: course.isMarketingMaterial,
      certificateId: course.certificateId,
      mode: sampleCourseId ? 1 : course.mode,
    });
    setData({
      activeAuthors: setInitialAuthors(course.courseAuthors),
      activeLessons: setInitialLessons(course.courseLessons),
      feedbacks: setInitialFeedbacks(course.courseFeedbacks),
      franchises: setInitialFranchises(course.courseFranchises),
      learnerTags: setInitialTags(course.courseTags, courseTags.learner),
      adminTags: setInitialTags(course.courseTags, courseTags.admin),
      lessons: [],
      lessonImage: {
        url: course.imageUrl,
      },
      quiz: course.quiz,
    });
  };

  const getCourseData = async (id, formExist) => {
    try {
      const { data } = await Api.get(`/wizard/course/${id}`);
      if (data?.data) {
        const { course, inUse } = data.data;
        setIsInUse(inUse);
        setCourse(course);
        setTempLocalActiveLessons(course?.courseLessons?.map(l => l?.lesson));
        if (!formExist) setCourseInitialData(course);
      }
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      history.push('/courses/all');
    }
  };

  const onPreviewClick = () => {
    history.push('/courses/preview', { course: { id, form, data }, activeStep });
  };

  const checkAndSetFromPreviewBack = () => {
    const fromPreviewState = location.state?.course;
    if (fromPreviewState) {
      setForm(fromPreviewState.form);
      setData(fromPreviewState.data);
    }
    if (location.state?.activeStep) {
      setActiveStep(location.state.activeStep);
    }
  };

  useEffect(() => {
    if (id) getCourseData(id, location.state?.course);
    if (sampleCourseId) getCourseData(sampleCourseId);
    checkAndSetFromPreviewBack();
    //eslint-disable-next-line
  }, [id]);

  const activeTypeKey = form.isMarketingMaterial ? 2 : 1;

  if (!form.isCertificateAvailable && !form.isMarketingMaterial) {
    const certificateIdx = typeData[activeTypeKey].steps.findIndex(
      ({ key }) => key === 'certificate',
    );
    typeData[activeTypeKey].steps.splice(certificateIdx, 1);
  }

  if (!form.isEvaluationAvailable) {
    const feedbackIdx = typeData[activeTypeKey].steps.findIndex(({ key }) => key === 'feedback');
    typeData[activeTypeKey].steps.splice(feedbackIdx, 1);
  }

  const typeSteps = typeData[activeTypeKey].steps;
  const isLastStep = activeStep === typeSteps.length - 1;
  const Step = typeSteps[activeStep].component;

  const canSaveDraft = form.mode === 1 || !id;

  if (id && !course) return <Loading className='mt-5' />;

  const isRequired = form.mode === 2;

  return (
    <div className='lesson-creation'>
      <Header
        steps={typeSteps}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        form={form}
        onPreviewClick={onPreviewClick}
      />
      <form
        className='py-3 has-header d-flex flex-column justify-content-between min-vh-100'
        onSubmit={handleStepChange}
      >
        <div className='col-12'>
          <Step
            tempLocalActiveLessons={tempLocalActiveLessons}
            form={form}
            setForm={setForm}
            data={data}
            setData={setData}
            onCourseSave={onCourseSave}
            fetching={fetching}
            isInUse={!!isInUse}
            isEditing={!!id}
            onSaveDraft={onSaveDraft}
            canSaveDraft={canSaveDraft}
            isRequired={isRequired}
            course={course}
          />
        </div>
        <FooterButtons
          form={form}
          canSaveDraft={canSaveDraft}
          fetching={fetching}
          onGoBackStep={onGoBackStep}
          activeStep={activeStep}
          isLastStep={isLastStep}
          onSaveDraft={onSaveDraft}
        />
      </form>
    </div>
  );
};

export default CourseNew;
