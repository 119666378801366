import React from 'react';
import CoursesProgressCircle from './CoursesProgressCircles';
import SpecialistCourses from './SpecialistCourses';

const RequiredCoursesSection = props => {
  const { product } = props;

  return (
    <div className='required-courses-section'>
      <div className='title-row'>
        <h2 className='section-title'>Product Coursework</h2>
        <p className='regular-text'>The indicators below show your progress on required courses.</p>
      </div>
      <CoursesProgressCircle progresses={product.progresses} />
      <SpecialistCourses requiredCourses={product?.currentPage?.requiredCourses} />
    </div>
  );
};

export default RequiredCoursesSection;
