import React from 'react';
import { useParams } from 'react-router-dom';
import { PAGE_STATUS_BY_NAME } from '../../Products/constants';
import ListActionButtons from 'shared/components/ListActionButtons';
import { hasAccess } from 'utils/permissionHelper';
import { modeStates } from 'app/Main/configs';
import useProductPageTitleTypes from '../../Products/useProductPageTypes';

const PageCard = ({ page, handleClickCopyLink, onItemRemove, onItemUnpublish, onItemPublish }) => {
  const TitleTypes = useProductPageTitleTypes();
  const bannerImg = page?.specialityImages?.[0]?.imageUrl;
  const duration = page?.requiredCourses?.length
    ? page.requiredCourses.reduce((acc, { course }) => acc + Number(course?.duration), 0)
    : 0;

  const params = useParams();

  const actions = [
    {
      type: 'link',
      icon: 'view',
      title: 'View',
      to: item => `/product/${params?.id}/product-page/${item.id}/preview`,
    },
    {
      type: 'link',
      icon: 'edit',
      title: 'Edit',
      disable: () => !hasAccess('products_create'),
      to: item => `/product/${params?.id}/product-page/${item.id}/edit`,
    },
    {
      type: 'button',
      icon: 'more',
      title: 'Copy Link',
      disable: () => !hasAccess('products_create'),
      onClick: handleClickCopyLink,
    },
    {
      type: 'link',
      icon: 'statistics',
      title: 'Statistics',
      disable: () => !hasAccess('products_create'),
      to: item => `/product-page/${item.id}/statistics`,
    },
    {
      type: 'button',
      icon: 'publish',
      title: 'Publish',
      disable: () => !hasAccess('products_create'),
      onClick: item => onItemPublish(item),
      hide: item => item.mode === modeStates.PUBLISHED,
    },
    {
      type: 'button',
      icon: 'unpublish',
      title: 'Unpublish',
      disable: () => !hasAccess('products_create'),
      onClick: item => onItemUnpublish(item),
      hide: item => item.mode === modeStates.UNPUBLISHED || item.mode === modeStates.DRAFT,
    },
    {
      type: 'button',
      icon: 'delete',
      title: 'Remove',
      disable: () => !hasAccess('products_create'),
      onClick: item => onItemRemove(item),
    },
  ];

  return (
    <div className='page-card'>
      <div className='banner-sec'>
        {bannerImg && <img className='banner-img' alt={page?.title} src={bannerImg} />}
        <div className='actions-block'>
          <ListActionButtons visibleButtonsCount={4} actions={actions} item={page} />
        </div>
      </div>
      <div className='details-sec'>
        <span className={`status ${PAGE_STATUS_BY_NAME[(page?.mode)]}`}>
          {PAGE_STATUS_BY_NAME[(page?.mode)]}
        </span>
        <div className='title-row'>
          {TitleTypes[page.titleIcon]}
          <h6 className='title'>{page?.title}</h6>
        </div>
        <div className='d-flex regular_txt col-gap-4'>
          Specialty: <b> {page.specialty}</b>
        </div>
        <p className='regular_txt desc'>{page.description}</p>
        <div className='properties-row'>
          <div className='item'>
            <p className='count'>{page?.registeredUsers || 0}</p>
            <p className='name'>Registered</p>
          </div>
          <div className='item'>
            <p className='count'>{page?.completedUsers || 0}</p>
            <p className='name'>Completed</p>
          </div>
          <div className='item'>
            <p className='count'>{page?.requiredCourses?.length || 0}</p>
            <p className='name'>Courses</p>
          </div>
          <div className='item'>
            <p className='count'>{duration || 0}</p>
            <p className='name'>Hours</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageCard;
