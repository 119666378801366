import React from 'react';

import ClickArea from 'shared/components/ClickArea';
import ListActionButtons from 'shared/components/ListActionButtons';
import Loading from 'shared/components/Loading';

import { formatDate } from 'utils/appHelpers';
import { hasAccess } from 'utils/permissionHelper';

import EmptyFrame from 'assets/marketing/spotlight-empty-frame.png';
import { SwitchIOSLike } from 'shared/components/SwitchIOSLike';

const headers = [
  {
    name: 'Name',
    className: 'col',
    key: 'name',
  },
  {
    name: 'Image',
    className: 'col-2',
    key: 'image',
  },
  {
    name: 'Frame',
    className: 'col-2',
    key: 'frame',
  },
  {
    name: 'Animation',
    className: 'col-2',
    key: 'animation',
  },
  {
    name: 'Added',
    className: 'col-2',
    key: 'createdAt',
  },
  {
    name: 'Actions',
    className: 'p-2 justify-content-start d-flex action-buttons-container min_100',
    key: '',
  },
];

const ENABLE_STATUS = {
  0: 'Disabled',
  1: 'Enabled',
};

const Lists = ({ data, order, changeOrder, fetch, onItemRemoveConfirm, handleSwitchEnabled }) => {
  if (fetch) return <Loading className='mt-5' />;
  if (!data || !data.length)
    return <div className='text-black-50 text-center mt-4'>No Results Found</div>;

  const actions = [
    {
      type: 'link',
      icon: 'edit',
      title: 'Edit',
      to: item => `/system/marketing-spotlight/edit/${item.id}`,
      hide: () => !hasAccess('system_countries_create'),
    },
    {
      type: 'link',
      icon: 'edit',
      title: 'Copy & Create',
      to: item => `/system/marketing-spotlight/new`,
      hide: () => !hasAccess('system_countries_create'),
    },
    {
      type: 'button',
      icon: 'edit',
      title: 'Remove',
      to: item => `/system/marketing-spotlight/edit/${item.id}`,
      onClick: item => onItemRemoveConfirm(item),
      hide: () => !hasAccess('system_countries_create'),
    },
  ];

  const handleOrderChange = item => {
    if (!item.key) return;
    changeOrder({
      orderKey: item.key,
      order: !order.order,
    });
  };

  return (
    <div className='users-lists'>
      <ul className='p-0'>
        <li className='d-flex list-header align-items-center'>
          {headers.map((item, index) => (
            <ClickArea
              key={item.name + index}
              className={`d-flex text-body justify-content-between align-items-center ${
                item.className
              }`}
              onClick={handleOrderChange.bind(null, item)}
            >
              {item.name}
              {item.key && order.orderKey === item.key && (
                <button
                  className={`btn ml-2 p-0 dropdown-toggle ${order.order ? '' : 'rotate-180'}`}
                />
              )}
            </ClickArea>
          ))}
        </li>
        {data &&
          data.map((item, index) => {
            return (
              <li key={index} className='d-flex align-items-center list-item h-not-set'>
                <div className='col text-truncate' title={item.name}>
                  {item.name}
                </div>
                <div className='col-2 text-truncate'>
                  <img
                    className='table-square-img'
                    alt={item.name}
                    src={item.imageUrl || EmptyFrame}
                  />
                </div>
                <div className='col-2 text-truncate'>
                  <img
                    className='table-square-img'
                    alt={item.name}
                    src={item.selectedFrame?.imageUrl || EmptyFrame}
                  />
                </div>
                <div className='col-2'>{ENABLE_STATUS[(item?.animation)]}</div>
                <div className='col-2'>{formatDate(item.createdAt)}</div>
                <div className='p-2 justify-content-end d-flex action-buttons-container'>
                  <SwitchIOSLike
                    checkedState={!!item?.enabled}
                    handleChange={() => handleSwitchEnabled(item)}
                  />
                  <ListActionButtons actions={actions} item={item} onlyDots />
                </div>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default Lists;
