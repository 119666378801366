import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { formatDateWithDots, getError } from 'utils/appHelpers';
import Loading from 'shared/components/Loading';
import { Api } from 'utils/connectors';
import AccordionTable from 'shared/components/AccordionTable';
import DetailsBlock from './components/DetailsBlock';
import SimpleListTable from 'shared/components/SimpleListTable';
import { PRODUCT_PAGE_STATUSES } from 'shared/constants';
import UserDefaultIcon from 'assets/icons/user-default.png';
import LinearStatusProgress from 'shared/components/LinearStatusProgress';

const productHeaders = [
  {
    name: 'Product Name',
    className: 'col-4 no-right-border',
    key: 'name',
  },
  {
    name: 'Date Added',
    className: 'col-2',
    key: 'createdAt',
  },
  {
    name: 'Registered',
    className: 'col-2',
    key: 'registeredUsers',
  },
  {
    name: 'Completed',
    className: 'col-2',
    key: 'completedUsers',
  },
];

const membersHeaders = [
  {
    name: 'User',
    className: 'col-5',
    key: 'name',
  },
  {
    name: 'Product Page',
    className: 'col',
    key: 'specialty',
  },
  {
    name: 'Progress',
    className: 'col',
    key: 'progress',
  },
  {
    name: 'Status',
    className: 'col',
    key: 'productStatus',
  },
];

const EntityView = ({ match, history }) => {
  const account = useSelector(state => state.account);
  const id = match.params.id ? match.params.id : account.entity ? account.entity.id : '';
  const { enqueueSnackbar } = useSnackbar();
  const [fetch, setFetch] = useState(false);
  const [entity, setEntity] = useState();
  const [form, setForm] = useState({
    uploadImageId: 0,
    originId: Number(id),
    name: '',
    address: '',
  });

  const getEntityData = async id => {
    if (!id) return;
    try {
      setFetch(true);
      const { data } = await Api.get(`/entity/getentity/${id}`);
      if (data.data.managers && data.data.managers.length) {
        data.data.members = [...data.data.managers, ...(data.data.members || [])];
      }
      setEntity(data.data);
      setFetch(false);
      setForm({ ...form, name: data.data.name, address: data.data.address });
    } catch (err) {
      history.push(`/entities`);
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  useEffect(() => {
    getEntityData(id);
  }, [id]);

  if (fetch || !entity) return <Loading className='w-100 mt-5' />;

  return (
    <div>
      <div className='users-header d-flex justify-content-between align-items-center'>
        <div className='d-flex breadcrumbs'>
          <Link to='/entities'>Entities</Link>
          <span className='mx-2'>/</span>
          <span>Entity Profile</span>
        </div>
      </div>
      <div className='d-flex col-12 has-header'>
        <div className='col-9 py-3'>
          <AccordionTable
            renderSummaryChild={item => (
              <li key={item.productId} className='d-flex list-item h-not-set list-item-white'>
                <div className='d-flex col-5 align-items-center no-right-border'>
                  <img
                    src={item.images?.[0]?.imageUrl}
                    alt={item.name}
                    className='user-image no-radius mr-2 shrink-0'
                  />
                  <Link to={`/product/${item?.productId}/view`}>
                    <span className='cell-text-regular'>{item.productName}</span>
                  </Link>
                </div>
                <div className='col-2 align-items-center d-flex cell-text-blue'>
                  {formatDateWithDots(item.createdAt) || '-'}
                </div>
                <div className='col-2 align-items-center d-flex cell-text-blue'>
                  {item?.registeredUsers || '0'}
                </div>
                <div className='col-2 align-items-center d-flex cell-text-blue'>
                  {item?.completedUsers || '0'}
                </div>
                <div className='p-2 d-flex align-items-center justify-content-center col-1'>
                  <ExpandMoreIcon />
                </div>
              </li>
            )}
            fetch={fetch}
            headers={productHeaders}
            list={entity?.products || []}
            renderDetailsChild={product => (
              <SimpleListTable
                headerClassName='bordered-list-header inner-list-header'
                noRightBorders
                headers={membersHeaders}
                list={product?.users}
                renderRow={user => (
                  <li key={user?.userId} className='d-flex list-item bordered-list-row h-not-set'>
                    <div className='d-flex col-5 align-items-center justify-content-between'>
                      <div className='d-flex align-items-center no-right-border cell-with-image'>
                        <img
                          src={user?.imageUrl || UserDefaultIcon}
                          alt={user?.name}
                          className='row-user-image no-radius mr-2 shrink-0 rounded'
                        />
                        <div className='user-cell-txt-block'>
                          <Link to={`/user/${user?.userId}`} className='cell-text-blue'>
                            {user?.fullName}
                          </Link>
                          <span className='cell-text-regular'>{user?.specialty}</span>
                        </div>
                      </div>
                    </div>
                    <div className='col align-items-center d-flex cell-text-blue'>
                      {user?.specialty}
                    </div>
                    <div className='col align-items-center d-flex'>
                      <LinearStatusProgress progress={user?.progress} status={user?.status} />
                    </div>
                    <div className='col align-items-center d-flex cell-text-blue'>
                      {PRODUCT_PAGE_STATUSES[(user?.status)]}
                    </div>
                  </li>
                )}
              />
            )}
            containerClassName='summary-type-white'
          />
        </div>
        <div className='col-3 py-3'>
          <DetailsBlock entity={entity} />
        </div>
      </div>
    </div>
  );
};

export default EntityView;
