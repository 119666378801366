/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { formatDate } from 'utils/appHelpers';
import Loading from 'shared/components/Loading';
import { SearchInput } from 'shared/components/SearchInput';
import { DEFAULT_IMG_URLS } from 'configs';

const PRODUCT_COURSE_TAGS = [{ id: 1, name: 'Required' }, { id: 2, name: 'Not Required' }];

const SpecialistCourses = props => {
  const { requiredCourses = [], isFetching } = props;
  const [search, setSearch] = useState('');
  const [activeSearchTags, setActiveSearchTags] = useState([]);

  const getCourses = async tags => {};

  const handleToggleSelectedTags = id => {
    if (activeSearchTags.includes(id)) {
      setActiveSearchTags(activeSearchTags.filter(tagId => tagId !== id));
    } else {
      setActiveSearchTags([...activeSearchTags, id]);
    }
  };

  const handleSearch = e => setSearch(e.target.value);

  return (
    <div className='specialist-courses-sec'>
      <div className='top-row'>
        <h5 className='row-title'>Specialist Courses</h5>
        <div className='search-block'>
          <div className='search-types-block'>
            {PRODUCT_COURSE_TAGS.map((tag, idx) => (
              <div
                key={idx}
                onClick={() => handleToggleSelectedTags(tag.id)}
                className={`search-type-item ${activeSearchTags.includes(tag.id) && 'active'}`}
              >
                {tag?.name}
              </div>
            ))}
          </div>
          <SearchInput
            onEnter={() => getCourses(activeSearchTags)}
            onChange={handleSearch}
            value={search}
            placeholder='Search in materials'
          />
        </div>
      </div>
      <div className='product-courses-block'>
        {!requiredCourses || isFetching ? (
          <Loading classView='mt-5 m-auto' />
        ) : (
          requiredCourses.map((course, index) => (
            <div key={index} className='landing-course-item'>
              <div className='img_block'>
                <img
                  className='course-image'
                  alt='course banner'
                  src={course.imageUrl || DEFAULT_IMG_URLS.course}
                />
              </div>
              <div className='title-block'>
                <div className='course-title'>{course.title}</div>
                <div className='create-date'> {formatDate(course.createdAt)}</div>
              </div>
              <div className='course-description'>{course.info}</div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default SpecialistCourses;
