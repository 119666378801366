/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { useSnackbar } from 'notistack';
import EditingInput from 'shared/components/editingForms/EditingInput';
import EditingTextArea from 'shared/components/editingForms/EditingTextArea';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import ReactTooltip from 'react-tooltip';
import { IconBin, ItemDeleteIcon } from 'shared/components/Icons';
import { hasAccess } from 'utils/permissionHelper';
import EditingSelect from 'shared/components/editingForms/EditingSelect';
import { SwitchIOSLike } from 'shared/components/SwitchIOSLike';
import Button from 'shared/components/Button';
import { DropDown, DropdownBody, DropdownHeader } from 'shared/components/DropDown';
import attachIcon from 'assets/attach.svg';
import useProductPageTitleTypes from '../../Products/useProductPageTypes';

const ProductPageGeneralStep = ({ form, setForm, isRequired, isEditing, product }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { productId } = useParams();

  const TitleTypes = useProductPageTitleTypes();

  const [specialties, setSpecialties] = useState([]);
  const [selectedSpecialties, setSelectedSpecialties] = useState([]);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);

  const isDefaultTitle =
    isEditing &&
    product &&
    (form.title === `${product.name} for Specialty` ||
      form.title === `${product.name} for ${form.specialities[0]}`);

  const getSpecialtiesList = async () => {
    try {
      const { data } = await Api.get(`product/specialties/${productId}`);
      setSpecialties(
        data?.data?.length ? data.data.map((specialty, idx) => ({ name: specialty, id: idx })) : [],
      );
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const getSubscriptionPlans = async () => {
    try {
      const { data } = await Api.get('/product/subscriptions');
      if (data?.data?.length) setSubscriptionPlans(data.data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const handleChange = ({ target }) => {
    const { name, value, checked, type } = target;
    const newForm = { ...form };

    const val = type === 'checkbox' ? (checked ? 1 : 0) : value;
    newForm[name] = val;
    setForm(newForm);
  };

  const handleSelectSpecialties = value => {
    const currentValue = Array.isArray(value) ? value : [value];
    setSelectedSpecialties(currentValue);
    let title = form.title;
    if ((!isEditing || isDefaultTitle) && currentValue?.length) {
      title =
        currentValue.length === 1
          ? `${product.name} for ${currentValue[0].name}`
          : `${product.name} for Specialty`;
    }
    setForm({ ...form, specialities: currentValue.map(spec => spec?.name), title });
  };

  const handleRemoveSpecialtyImg = index => {
    const currentImages = [...form.specialityImageUrl];
    currentImages.splice(index, 1);
    setForm({ ...form, specialityImageUrl: currentImages });
  };

  const handleToggleSwitch = ({ target }) => {
    const { name } = target;
    setForm({ ...form, [name]: form[name] ? 0 : 1 });
  };

  const handleAddExternalLink = () => {
    setForm({
      ...form,
      externalLink: [...form.externalLink, { title: '', description: '', productLink: '' }],
    });
  };

  const handleRemoveExternalLink = index => {
    const currentLinks = [...form.externalLink];
    currentLinks.splice(index, 1);

    setForm({
      ...form,
      externalLink: currentLinks.length
        ? currentLinks
        : [{ title: '', description: '', productLink: '' }],
    });
  };

  const handleChangeExternalLink = ({ name, value }, index) => {
    const currentLinks = form.externalLink;
    currentLinks.splice(index, 1, { ...currentLinks[index], [name]: value });
    setForm({ ...form, externalLink: currentLinks });
  };

  const onImageUpload = async e => {
    try {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      formData.append('isdraft', 'true');
      const res = await Api.post('/upload', formData);
      setForm({
        ...form,
        specialityImageUrl: form.specialityImageUrl
          ? [...form.specialityImageUrl, res?.data?.data?.url]
          : [res?.data?.data?.url],
      });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const handleSelect = key => {
    setForm({ ...form, titleIcon: key });
  };

  const getOptionLabel = option => (
    <div className='d-flex align-items-center'>
      {selectedSpecialties.find(o => o.id === option.id) ? '✓ ' : ''}
      {option.name}
    </div>
  );

  const isOptionSelected = option => {
    return selectedSpecialties.some(o => o.id === option.id);
  };

  useEffect(() => {
    getSpecialtiesList();
    getSubscriptionPlans();
  }, []);

  useEffect(() => {
    if (!selectedSpecialties?.length) {
      setSelectedSpecialties(
        form.specialities?.length
          ? form.specialities.map((specialty, idx) => ({ name: specialty, id: idx }))
          : [],
      );
    }
  }, [form.specialities]);

  return (
    <div className='col-9 px-2'>
      <div className='p-0 user-creation-form'>
        <ul className='p-0'>
          <li className='d-flex align-items-center'>
            <div className='col-4 pl-0'>
              <label className='m-0'>Specialty*:</label>
            </div>
            <Select
              className='multi-select'
              classNamePrefix='multi-select-pre'
              getOptionLabel={getOptionLabel}
              getOptionValue={o => o.id}
              name='specialties'
              value={selectedSpecialties}
              onChange={handleSelectSpecialties}
              isMulti={!isEditing}
              options={specialties}
              closeMenuOnSelect={isEditing}
              placeholder='Select Specialist'
              isOptionSelected={!isEditing && isOptionSelected}
            />
          </li>
          <li className='d-flex align-items-center flex-wrap upload-multi-images'>
            <div className='col-4 pl-0'>
              <label className='m-0'>Specialty Image*:</label>
            </div>
            <div className='col-8 upload-file-form overflow-hidden p-0'>
              <input
                id='file'
                name='file'
                type='file'
                className='flex-fill fixed-hidden-input'
                onChange={onImageUpload}
                required={!form.specialityImageUrl?.length}
              />
              <label
                htmlFor='file'
                className='d-flex justify-content-between align-items-center text-nowrap'
              >
                Upload Image
                <div>
                  <img src={attachIcon} className='ml-2' alt='icon' />
                </div>
              </label>
            </div>
            {!!form.specialityImageUrl?.length && (
              <div className='images-block col-8 ml-auto'>
                {form.specialityImageUrl.map((url, idx) => {
                  return (
                    <div key={idx} className='img-block'>
                      <div onClick={() => handleRemoveSpecialtyImg(idx)} className='remove-icon'>
                        <ItemDeleteIcon bgColor='white' />
                      </div>
                      <img alt='specialty' src={url} />
                    </div>
                  );
                })}
              </div>
            )}
          </li>
          <li className='d-flex'>
            <div className='col-4 pl-0 pt-1'>
              <label className='m-0'>Specialty Message*:</label>
            </div>
            <EditingTextArea
              type='text'
              name='specialityMessage'
              value={form.specialityMessage}
              onChange={handleChange}
              className='p-0 is-large'
              placeholder='Enter Specialty Message'
              required
              editing={true}
              access={true}
              maxLength='1024'
            />
          </li>
          <li className='d-flex align-items-center'>
            <div className='col-4 pl-0'>
              <label className='m-0'>Subscription Plan*:</label>
            </div>
            <EditingSelect
              parentClassName='z-0'
              name='subscriptionId'
              onChange={handleChange}
              value={form.subscriptionId}
              items={subscriptionPlans}
              label='Select Related Subscription Plan'
              useProperty='id'
              displayProperty='title'
              required
              editing
              lightGrayLabel
            />
          </li>
          <h6 className='color-blue mb-3 mt-3 pl-4'>Product Specialty Page Content</h6>
          <li className='d-flex align-items-center'>
            <div className='col-4 pl-0'>
              <label className='m-0'>Page Title*:</label>
            </div>
            <DropDown value={form.titleIcon}>
              <DropdownHeader className='product-page-dropdown-header'>
                {TitleTypes[form.titleIcon]}
              </DropdownHeader>
              <DropdownBody className='product-dropdown-body'>
                <ul className='product-title-dropdown p-0'>
                  {Object.keys(TitleTypes).map((key, index) => {
                    return (
                      <li
                        className={`product-title-item ${form.titleIcon === key &&
                          'global_disabled'}`}
                        key={index}
                        onClick={() => handleSelect(key)}
                      >
                        {TitleTypes[key]}
                      </li>
                    );
                  })}
                </ul>
              </DropdownBody>
            </DropDown>
            <EditingInput
              type='text'
              name='title'
              value={form.title}
              onChange={handleChange}
              className='p-0'
              placeholder='Enter Title'
              required={isRequired}
              editing={true}
              access={true}
            />
          </li>
          <li className='d-flex'>
            <div className='col-4 pl-0 pt-1'>
              <label className='m-0'>Page Description*:</label>
            </div>
            <EditingTextArea
              type='text'
              name='description'
              value={form.description}
              onChange={handleChange}
              className='p-0 is-large'
              placeholder='Enter Description'
              required
              editing
              maxLength='1024'
            />
          </li>
          <h6 className='color-blue mb-3 mt-3 pl-4'>Product Page Additional Sections</h6>
          {form.externalLink.map((link, idx) => {
            const isEmpty =
              !form.externalLink[idx]?.title &&
              !form.externalLink[idx]?.productLink &&
              !form.externalLink[idx]?.description;
            return (
              <li className='d-flex'>
                <div className='col-4 pl-0 pt-1'>
                  <label className='m-0'>External Link:</label>
                </div>
                <div className='form-inputs-block d-flex flex-column w-100'>
                  <EditingInput
                    type='text'
                    name='title'
                    value={link.title}
                    onChange={({ target }) => handleChangeExternalLink(target, idx)}
                    className='p-0'
                    placeholder='Enter Product Title'
                    editing={true}
                    access={true}
                  />
                  <EditingInput
                    type='text'
                    name='productLink'
                    value={link.productLink}
                    onChange={({ target }) => handleChangeExternalLink(target, idx)}
                    className='p-0'
                    placeholder='Enter Product Link'
                    editing={true}
                    access={true}
                  />
                  <EditingTextArea
                    type='text'
                    name='description'
                    value={link.description}
                    onChange={({ target }) => handleChangeExternalLink(target, idx)}
                    className='p-0 is-large'
                    placeholder='Enter Product Description'
                    editing
                    maxLength='1024'
                  />
                  <div className='external-link-actions'>
                    {idx === form.externalLink?.length - 1 && form.externalLink?.length < 2 && (
                      <Button
                        onClick={handleAddExternalLink}
                        className='btn-outline-blue add-link-btn'
                      >
                        Add External link +
                      </Button>
                    )}
                    {!(isEmpty && form.externalLink.length === 1) && (
                      <Button
                        disabled={!hasAccess('products_create')}
                        className={`btn-sm btn-blue text-orange text-nowrap remove-btn fz-12 fw-600 d-flex align-items-center justify-content-center ${!hasAccess(
                          'products_create',
                        ) && 'global_disabled'}`}
                        onClick={() => handleRemoveExternalLink(idx)}
                      >
                        <IconBin className='mr-2' />
                        Delete
                      </Button>
                    )}
                  </div>
                </div>
              </li>
            );
          })}
          <li className='d-flex align-items-center'>
            <div className='col-3 pl-0'>
              <label className='m-0'>Recommended Courses:</label>
            </div>
            <div className='switch_container'>
              <SwitchIOSLike
                name='recomendedCourses'
                checkedState={!!form.recomendedCourses}
                handleChange={handleToggleSwitch}
              />
            </div>
            <span className='switch_label'>{form.recomendedCourses ? 'Enabled' : 'Disabled'}</span>
          </li>
          <li className='d-flex align-items-center'>
            <div className='col-3 pl-0'>
              <label className='m-0'>Recently Watched Courses:</label>
            </div>
            <div className='switch_container'>
              <SwitchIOSLike
                name='recentlyWatchedCourses'
                checkedState={!!form.recentlyWatchedCourses}
                handleChange={handleToggleSwitch}
              />
            </div>
            <span className='switch_label'>
              {form.recentlyWatchedCourses ? 'Enabled' : 'Disabled'}
            </span>
          </li>
        </ul>
      </div>
      <ReactTooltip effect='solid' type='light' multiline={true} backgroundColor='#FFF' />
    </div>
  );
};

export default ProductPageGeneralStep;
