import React from 'react';

const SpecialtySection = props => {
  const { specialty, specialityMessage, specialityImages } = props.currentPage || {};
  const specialtyBannerImg = specialityImages && specialityImages?.[0]?.imageUrl;

  const showingSpecialty = specialty === 'Fellowship in Radiology' ? 'Radiology Tech' : specialty;

  return (
    <div className='specialty-section'>
      <div className='inner-block first-block'>
        <h2 className='section-title'>{showingSpecialty}</h2>
        <p className='regular-text'>{specialityMessage}</p>
      </div>
      <div className='inner-block second-block'>
        {specialtyBannerImg && (
          <img className='img-cover' alt={specialty} src={specialtyBannerImg} />
        )}
      </div>
    </div>
  );
};

export default SpecialtySection;
