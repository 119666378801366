import React from 'react';

import { lessonTypesObj } from 'app/Main/configs';
import { bindDurationMinutes, formatDate } from 'utils/appHelpers';

import { IconVideos } from 'shared/components/Icons';

import IconActivePdf from 'assets/wizard/active_pdf.svg';
import { SwitchIOSLike } from 'shared/components/SwitchIOSLike';

const InfoIcon = ({ name, value, className }) => (
  <p className={`mr-2 mb-0 ${className || ''}`}>
    {name}: <b className='font-weight-normal'>{value}</b>
  </p>
);

const CourseRow = ({
  item,
  index,
  onToggleItemRequired,
  activeIds = [],
  checkField = 'lessonId',
  defaultImageUrl,
}) => {
  const isPdf = item.contentType === 2;
  return (
    <div className='d-flex course-lessons-row w-100'>
      <div className={`image-container ${!item.imageUrl ? 'no-image' : ''}`}>
        <img
          src={item.imageUrl ? item.imageUrl : isPdf ? IconActivePdf : defaultImageUrl}
          alt='img'
        />
      </div>
      <div className='author-name mb-1 w-100'>
        <div className='d-flex justify-content-between'>
          <h5>
            {item.title} <span className='ml-2'>{lessonTypesObj[item.contentType]}</span>
          </h5>
          <div className='switch-block'>
            {activeIds.includes(item.courseId) ? (
              <span className='switch-label bold'>Required</span>
            ) : (
              <span className='switch-label'>Not Required</span>
            )}
            <SwitchIOSLike
              checkedState={activeIds.includes(item.courseId)}
              handleChange={() => onToggleItemRequired(item.courseId)}
            />
          </div>
        </div>
        <p className='note'>{item.info}</p>
        <div className='d-flex'>
          <InfoIcon
            name='Chapters'
            value={
              <strong className='font-weight-bold'>
                {item.chapters || '-'}
                <IconVideos color='#131F6B' height='11' />
              </strong>
            }
          />
          <InfoIcon name='Date' value={formatDate(item.createdAt) || '-'} />
          <InfoIcon name='Duration' value={bindDurationMinutes(item.duration) || '-'} />
          <InfoIcon name='Nickname' value={item.nickName || '-'} />
        </div>
      </div>
    </div>
  );
};

export default CourseRow;
